import { useState, ReactNode, useEffect, useRef } from 'react'
import { Input } from '@nbit/arco'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import Icon from '@/components/icon'
import styles from './index.module.css'

export interface InputProps {
  /** 输入框前添加元素 */
  addBefore?: ReactNode
  /** 输入框上面文字 */
  label?: string
  /** 输入框值改变回调 */
  onChange: (value) => void
  /** 规则验证 */
  ruleCalibration: boolean | null
  /** 输入框提示文字 */
  placeholder: string
  /** 添加后缀文字或者图标 */
  suffix?: ReactNode
  /** 输入框最大值 */
  maxLength?: number
  /** 输入框状态 */
  type?: string
  /** 输入框默认值 */
  defautValue?: string
  /** 输入框聚焦回调 */
  onBlur?: (value) => void
  /** 允许清空输入框 */
  allowClear?: boolean
  /** 清除按钮的图标 */
  clearIcon?: ReactNode
}

function UserInputStyle({
  addBefore,
  label,
  onChange,
  ruleCalibration,
  placeholder,
  suffix,
  maxLength,
  type,
  defautValue = '',
  onBlur,
  allowClear = false,
}: InputProps) {
  const [error, setError] = useState<boolean | null>()
  const [value, setValue] = useState<string>(defautValue || '')
  const inputRef = useRef<HTMLInputElement | any>(null)

  useEffect(() => {
    setError(ruleCalibration)
  }, [ruleCalibration])

  useEffect(() => {
    setValue(defautValue)
  }, [defautValue])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <div
      className={classNames(`${styles.scoped} input-style-box`, {
        'input-success': error === false,
        'input-error': error,
      })}
    >
      <span
        className={classNames('input-label', {
          'label-success': error === false,
          'label-error': error,
        })}
      >
        {label}
        <span className="input-must">*</span>
      </span>
      <Input
        placeholder={placeholder}
        addBefore={addBefore}
        ref={inputRef}
        value={value}
        suffix={suffix}
        maxLength={maxLength}
        type={type}
        onChange={v => {
          setValue(v)
          onChange(v)
        }}
        allowClear={allowClear}
        clearIcon={<Icon name="icon_pop_close" className="allowclear-close-icon" />}
        onBlur={onBlur}
      />
    </div>
  )
}

export default UserInputStyle
